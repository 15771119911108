import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import styles from  "./header.module.scss"

const Header = ({ title, currentUrl, description }) => {
  const { site } = useStaticQuery(graphql`
    query titleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <header className={styles.Header}>
      <h1>
        <Link to="/">
          {title || site.siteMetadata.title}
        </Link>
      </h1>
      <h2>{description}</h2>
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  currentUrl: PropTypes.string,
  description: PropTypes.string,
}

Header.defaultProps = {
  title: '',
  currentUrl: '/',
  description: 'The best cafes to work in around the world.'
}

export default Header
