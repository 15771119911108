import React from "react";
import { Link } from "gatsby";

import SearchBar from './SearchBar'
import { CAFE, MAP } from './utils/constants';

/* Icons */
import HomeIcon from "../images/home.svg";
import ListIcon from "../images/list.svg";
import MapIcon from "../images/map.svg";

import "./NavBar.scss";

const NavBar = ({
  handleSearch,
  searchValue,
  setSearchValue,
  toggleView,
  toggles,
  view,
  cafeData
}) => {
  const filterToggles = toggles.map((item, idx) => (
    <div key={idx} className={`FilterToggle ${item.active && 'Active'}`} onClick={item.toggle}>
      {item.label}
    </div>
  ))

  return (
    <nav className="App-nav">
      <div className="App-nav-inner">
        <Link className="HomeLink" to="/"><HomeIcon /></Link>
        <SearchBar
          cafeData={cafeData}
          handleSearch={handleSearch}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <div className="ViewToggle">
          {view === MAP || view === CAFE ? (
            <ListIcon onClick={toggleView} />
          ) : (
            <MapIcon onClick={toggleView} />
          )}
        </div>
      </div>
      <div className="BooleanFilters">
        {filterToggles}
      </div>
    </nav>
  )
}

export default NavBar
