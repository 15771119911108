const CAFE_GRIND_SPREADSHEET = "https://docs.google.com/spreadsheets/d/1fjU6sutEcdVvbQzZbNNk4h5xQXZ4R0GqG0dV_QOmGhI/edit?usp=sharing";

const CAFE = 'cafe',
      NEIGHBORHOOD = 'neighborhood',
      CITY = 'city';

const LIST = "list",
      MAP  = "map";

export { CAFE_GRIND_SPREADSHEET, CAFE, NEIGHBORHOOD, CITY, LIST, MAP };
