/* Map totalScore values to qualitative evaluation */
const mapScoreToValue = (score, range) => {
  if (!score || isNaN(score)) return {'lvl': 0, 'txt': '?'};
  if (score <= range[0]) return {'lvl': 1, 'txt': 'bleh'};
  if (score <= range[1]) return {'lvl': 2, 'txt': 'meh'};
  if (score <= range[2]) return {'lvl': 3, 'txt': 'okay'};
  if (score <= range[3]) return {'lvl': 4, 'txt': 'good'};
  return {'lvl': 5, 'txt': 'great'};
}

function titleCase(str) {
  return str.toLowerCase().split(' ').map(function(word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

export { mapScoreToValue, titleCase };
