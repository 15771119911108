import React from "react";

import "./AddCafeButton.scss";

const AddCafeButton = ({link, text}) => (
  <a className="AddCafeButton" href={link}>
    {text}
  </a>
)

export default AddCafeButton
