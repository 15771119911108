import React from "react"
import PropTypes from "prop-types"

import { FooterStyle, TwitterLinkStyle } from "./footer.module.scss"

const Footer = ({siteTitle}) => {
  return (
    <footer className={FooterStyle}>
      <p>
        © {new Date().getFullYear()} {siteTitle}, <a className={TwitterLinkStyle} href="https://formico.io">Formico LLC</a>
      </p>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
