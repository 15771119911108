import React from "react";
import CafeMap from "./CafeMap";
import CafeCard from "./CafeCard";
import "./MapContainer.scss";

const NAV_BASE_URL = "https://www.google.com/maps/search/";

const cafeDetails = (selectedCafe) => {
  const CafeName = selectedCafe.CafeName ? selectedCafe.CafeName.trim() : '';
  const Address = selectedCafe.Address ? selectedCafe.Address.trim() : '';
  const navLink =
    NAV_BASE_URL +
    (CafeName + "+" + Address).replace(/[\s]/g, "+");

  return (
    <div className="cafeDetailsContainer">
      <div className="cafeDetailsContainerInner">
        <CafeCard cafeInfo={selectedCafe} />
        <section className="cafeDetails">
          { selectedCafe.Notes &&
            <>
              <h4 className="label">Notes:</h4>
              <p>{selectedCafe.Notes}</p>
            </>
          }
          { selectedCafe.WifiNetwork && selectedCafe.WifiPassword &&
            <>
              <h4 className="label">Network:</h4>
              <p>{selectedCafe.WifiNetwork}</p>
              <h4 className="label">Password:</h4>
              <p>{selectedCafe.WifiPassword}</p>
            </>
          }
          { selectedCafe.Address &&
            <>
              <h4 className="label">Address:</h4>
              <p className="addressDetail">
                <a href={navLink}>
                  {Address}
                </a>
              </p>
            </>
          }
        </section>
      </div>
    </div>
  );
};

const MapContainer = ({ data, selectedCafe, selectCafe }) => (
  <div className="map-container">
    {selectedCafe && cafeDetails(selectedCafe)}
    <CafeMap
      data={data}
      selectCafe={selectCafe}
      selectedCafe={selectedCafe}
    />
  </div>
);

export default MapContainer;
