import React from "react";
import { stringToUrl } from "./utils/urls";
import { mapScoreToValue } from './utils'
import "./CafeCard.scss";

const CafeCard = props => {
  const { onClick, cafeInfo } = props;
  const {
    CafeName,
    TotalScore,
    Power,
    CoffeeScore,
    FoodScore,
    VibeScore,
    WorkScore,
    SeatsScore,
    Price,
    WifiDown,
    WifiUp
  } = cafeInfo;

  // TODO: Replace emoji with svg icons to fix differences between browsers
  const scoreData = [
    {
      icon: "☕️",
      score: CoffeeScore,
      title: "coffee"
    },
    {
      icon: "🥪",
      score: FoodScore,
      title: "food"
    },
    {
      icon: "☮️",
      score: VibeScore,
      title: "vibe"
    },
    {
      icon: "💻",
      score: WorkScore,
      title: "work"
    },
    {
      icon: "🛋",
      score: SeatsScore,
      title: "seats"
    }
  ];

  const tertData = [
    {
      label: "🔌",
      value: Power,
      title: "Are there outlets?"
    },
    {
      label: "💰",
      value: Price,
      title: "Price of a small coffee"
    },
    {
      label: "📶",
      value: WifiDown,
      title: "WiFi down in Mbps"
    },
    {
      label: "📶",
      value: WifiUp,
      title: "WiFi up in Mbps"
    }
  ];

  /* see detailed attribute scores at a glance */
  const scoreList = scoreData.map((item, i) => {
    const mappedScore = mapScoreToValue(parseInt(item.score), [1, 2, 3, 4]);
    return (
      <li key={i} className={`ScoreListItem lvl-${mappedScore.lvl}`}>
        <span className="IconLabel" title={item.title}>{item.icon}</span>
        <p className="DetailedScore">{item.score}</p>
      </li>
    )
  });

  const tertDataList = tertData.map((item, i) => (
    <li key={i} className="TertDataItem">
      <span className="TertLabel" title={item.title}>{item.label}</span>
      <p className="TertValue">
        {item.value || "?"}
      </p>
    </li>
  ));
  const mappedScore = mapScoreToValue(WifiDown && TotalScore, [11, 13, 15, 17]);
  return (
    <a
      className="CafeCard"
      href={`/cafe/${stringToUrl(CafeName)}`}
      onClick={(e) => {
        if (!e.metaKey) {
          onClick()
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <h3>{CafeName}</h3>
      <div className="InnerCard">
        <div className="TopSection">
          <div className={`TotalScoreBadge lvl-${mappedScore.lvl}`}>
            <p>{TotalScore}</p>
            <h4>{mappedScore.txt}</h4>
          </div>
          <ol className="TertList">{tertDataList}</ol>
        </div>
        <ol className="ScoreList">{scoreList}</ol>
      </div>
    </a>
  );
};

CafeCard.defaultProps = {
  onClick: () => {},
  cafeInfo: [{
    CafeName: '',
    TotalScore: '',
    Address: '',
    Power: '',
    CoffeeScore: '',
    FoodScore: '',
    VibeScore: '',
    WorkScore: '',
    SeatsScore: '',
    Price: '',
    WifiDown: '',
    WifiUp: ''
  }]
}
export default CafeCard;
