import React, { useEffect, useState } from "react";
import { Map, TileLayer, Marker } from "react-leaflet";
import L from 'leaflet'
import { mapScoreToValue } from './utils'
import "./CafeMap.scss";

const DEFAULT_VIEWPORT = {
  center: [10, -30],
  zoom: 0
};

const makeCafeIcon = (score) => {
  const mappedScore = mapScoreToValue(score, [11, 13, 15, 17]);
  // TODO: find a better solution. svg needs to be loaded manually to control the color
  return new L.divIcon({
    className: `divIcon icon-lvl-${mappedScore.lvl}`,
    iconAnchor: [0, 24],
    popupAnchor: [16, -24],
    html: `
      <div class="MapIcon icon-lvl-${mappedScore.lvl}">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="2em" width="2em">
          <path d="M48 400h368v48H48zM424 64H80v224c0 44 36 80 80 80h144c44 0 80-36 80-80v-64h40c22 0 40-18 40-40v-80c0-22-18-40-40-40zm0 112h-40v-64h40v64z"/>
        </svg>
      </div>
    `
  });
}

const CafeMap = ({ data, selectCafe, selectedCafe }) => {
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT);

  useEffect(() => {
    /* Calculate starting viewpoint */
    let center = null;
    let i = 0;

    while (!center && i < data.length) {
      const cafe = selectedCafe || data[i];
      const lat = Number(cafe.Lat);
      const lng = Number(cafe.Lng);

      if (lat && lng) {
        center = [lat, lng];
        const new_viewport = {
          center,
          zoom: viewport.zoom || 13
        };
        setViewport(new_viewport);
      }

      i++;
    }

    if (!center) {
      setViewport(DEFAULT_VIEWPORT);
    }
  }, [data, selectedCafe]); // don't include viewport or viewport.zoom, causes jankiness

  if (typeof window !== 'undefined') {
    return (
      <Map
        className="CafeMap"
        onViewportChanged={setViewport}
        viewport={viewport}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {data.map((cafe, i) => {
          if (!cafe.length >= 22) return null;
          try {
            const isSelected = !selectedCafe || (
              selectedCafe.CafeName === cafe.CafeName
              && selectedCafe.Address === cafe.Address
            );
            const cafeLat = parseFloat(cafe.Lat),
              cafeLong = parseFloat(cafe.Lng),
              cafePosition = [cafeLat, cafeLong];
            if (!cafeLat || !cafeLong) return null;
            return (
              <Marker
                opacity={!selectedCafe || isSelected ? 1 : 0.5}
                key={i}
                position={cafePosition}
                icon={makeCafeIcon(cafe.WifiDown && cafe.TotalScore)}
                onClick={() => selectCafe(cafe)}
                zIndexOffset={isSelected ? 50 : 0}
              />
            );
          } catch (e) {
            console.log(e);
            return null;
          }
        })}
      </Map>
    );
  }
  return <p>no window</p>
};

export default CafeMap;
