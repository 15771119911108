import React, { useState } from "react";
import Autosuggest from 'react-autosuggest';

import HomeIcon from "../images/home.svg";
import BusinessIcon from "../images/business.svg";
import CafeIcon from "../images/cafe.svg";

import { CAFE, NEIGHBORHOOD, CITY } from "./utils/constants";

import cafeNames from "../../data/names.json";
import cities from "../../data/cities.json";
import neighborhoods from "../../data/neighborhoods.json";

import './SearchBar.scss';

const SearchBar = ({ cafeData, handleSearch, searchValue, setSearchValue }) => {
  const [suggestions, setSuggestions] = useState([])

  const onChange = (event, { newValue }) => {
    setSearchValue(newValue)
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    console.log(suggestion);
    handleSearch(suggestion);
  }

  const renderSuggestion = suggestion => {
    let icon;
    switch(suggestion.type) {
      case CAFE:
        icon = <CafeIcon className="searchIcon" />;
        break;
      case CITY:
        icon = <BusinessIcon className="searchIcon" />;
        break;
      case NEIGHBORHOOD:
        icon = <HomeIcon className="searchIcon" />;
        break;
      default:
        break;
    }
    return (
      <div className="searchResult" >
        {icon}
        {suggestion.value}
      </div>
    )
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value ? value.trim().toLowerCase() : '';
    let dropdownValues = []

    if (inputValue.length > 2) {
      const typeMap = {
        [CAFE]: cafeNames,
        [CITY]: cities,
        [NEIGHBORHOOD]: neighborhoods
      }
      Object.keys(typeMap).forEach(key => {
        typeMap[key].forEach(name => {
          if (name.toLowerCase().includes(inputValue)) {
            dropdownValues.push({ type: key, value: name });
          }
        });
      });
    }
    setSuggestions(dropdownValues)
  };

  const inputProps = {
    placeholder: 'Search',
    value: searchValue,
    onChange
  };

  return (
    <Autosuggest
      focusInputOnSuggestionClick={false}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={suggestion => suggestion.value}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={onSuggestionSelected}
    />
  )
}

export default SearchBar
