import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component"
import CafeCard from "./CafeCard.js";
import "./CafeList.scss";

const CafeCards = ({
  cafes,
  loading,
  fetchCafes,
  position,
  totalDataLength,
  onSelect
}) => {
  const cards = cafes.map((row, i) => {
    return (
      <CafeCard
        key={i + position}
        cafeInfo={row}
        onClick={() => onSelect(row)}
      />
    );
  });

  return (
    <InfiniteScroll
      dataLength={cafes.length}
      next={() => fetchCafes()}
      hasMore={cafes.length < totalDataLength}
      scrollThreshold={1}
      style={{ overflow: 'unset' }}
      endMessage={
        <p className="CafeListEnd">
          No more results <span role="img" aria-label="coffee">☕️</span>
          <br /><br />
          Try removing filters to expand your search.
        </p>
      }
      loader={
        <p style={{ textAlign: "center", marginTop: "1%" }}>
          Brewing...
        </p>
      }
    >
      <ul className="CafeList">
        {!loading ? cards : ""}
      </ul>
    </InfiniteScroll>
  );
};

const CafeList = ({ data, onSelect, appId }) => {
  const [pos, setPos] = useState(0);
  const [cafes, setCafes] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCafes = (reset=false) => {
    if (!data) return;

    // We want some data to render automatically on page
    // load, and when any filter is applied or removed.
    const stepSize = 36;
    if (reset) {
      // If data changed, reset the cafe list and position index.
      setCafes([...data.slice(0, 24)]);
      setPos(24);
    } else {
      // Otherwise, append new data to existing cafe data and increment pos.
      setCafes([...cafes, ...data.slice(pos, pos + stepSize)]);
      setPos(pos + stepSize);
    }

    // Remove loading indicator
    setLoading(false);
  }

  // Re-render data when new data is received (some filter applied)
  useEffect(() => {
    fetchCafes(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <CafeCards
      cafes={cafes}
      loading={loading}
      fetchCafes={fetchCafes}
      position={pos}
      totalDataLength={data.length}
      onSelect={onSelect}
    />
  )
};

export default CafeList;
