import React, { useEffect, useState } from "react";
import SEO from "../layout/seo";
import Header from "../layout/header"
import CafeList from "./CafeList";
import MapContainer from "./MapContainer";
import AddCafeButton from "./AddCafeButton";
import NavBar from "./NavBar";

import {
  CAFE_GRIND_SPREADSHEET, CAFE, LIST, MAP
} from "./utils/constants";

import { titleCase } from "./utils";
import { stringToUrl } from "./utils/urls";

/* Import Cafe Data */
import cafeDataRaw from "../../data/cafe-data.json";

import "./app.scss";

const App = (props) => {
  // SEO
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState();

  const [cafeData, setCafeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCafe, selectCafe] = useState();

  /* Switch between map and list view */
  const [view, setView] = useState(LIST);

  /* Filters */
  const [searchValue, setSearchValue] = useState('');
  const [toggles, setToggles] = useState([]);

  /* Initalize data state on initial load */
  useEffect(() => {
    if (!cafeDataRaw) {
      console.error('there was an error loading cafe data');
    } else {
      const d = cafeDataRaw.map(row => ({
        CafeName: row[0],
        TotalScore: row[1],
        Country: row[2],
        City: row[3],
        Neighborhood: row[4],
        Address: row[5],
        Power: row[6],
        CoffeeScore: row[7],
        FoodScore: row[8],
        VibeScore: row[9],
        WorkScore: row[10],
        SeatsScore: row[11],
        Price: row[12],
        WifiNetwork: row[13],
        WifiPassword: row[14],
        WifiDown: row[15],
        WifiUp: row[16],
        Notes: row[17],
        Lat: row[18],
        Lng: row[19],
        RelativeScore: row[20]
      }))
      setCafeData(d);
    }
  }, []);

  const WIFI = 'fast-wifi',
        POWER = 'power-outlets',
        GREAT = 'great',
        FOOD = 'good-food',
        COFFEE = 'good-coffee',
        SEATS = 'good-seats';

  useEffect(() => {
    const { uri, searchString } = props;
    if (!uri || !searchString) {
      setView(LIST);
      setSearchValue('');
      setToggles([]);
      setFilteredData([]);
      selectCafe();
      return;
    }

    // Extract view from url
    if (uri.includes('/cafe/')) {
      setSearchValue(searchString.replace(/-/g, ' '));
      setView(CAFE);
    }
    else if (uri.includes('/cafe-list/')) setView(LIST);
    else if (uri.includes('/cafe-map/')) setView(MAP);
    else setView(LIST);

    // Extract filters
    const t = [WIFI, POWER, FOOD, COFFEE, SEATS].filter(toggle => {
      const re = new RegExp(`(cafes-with-|-and-)(${toggle})`);
      return(searchString.match(re));
    });
    if (searchString.match(/^great-/)) t.push(GREAT);
    setToggles(t);

    // Extract location
    const locationMatch = searchString.match(/-in-(.*)$/);
    if (locationMatch) setSearchValue(locationMatch[1].replace(/-/g, ' '));
    setTitle(titleCase(searchString.replace(/-/g, ' ')));
  }, [props])


  const setToggle = toggle => toggles.includes(toggle)
    ? setToggles(toggles.filter(t => t !== toggle))
    : setToggles(toggles.concat(toggle));

  const toggleView = () => {
    if (view === MAP || view === CAFE) {
      setView(LIST)
      selectCafe();
    } else {
      setView(MAP);
    }
  }

  const toggleButtons = [
    {
      label: "Wifi > 20Mbps",
      toggle: () => setToggle(WIFI),
      active: toggles.includes(WIFI)
    },
    {
      label: "Power Outlets",
      toggle: () => setToggle(POWER),
      active: toggles.includes(POWER)
    },
    {
      label: "Only The Best",
      toggle: () => setToggle(GREAT),
      active: toggles.includes(GREAT)
    },
    {
      label: "Good Food",
      toggle: () => setToggle(FOOD),
      active: toggles.includes(FOOD)
    },
    {
      label: "Good Coffee",
      toggle: () => setToggle(COFFEE),
      active: toggles.includes(COFFEE)
    },
    {
      label: "Good Seats",
      toggle: () => setToggle(SEATS),
      active: toggles.includes(SEATS)
    },
  ]

  // Search for text matches in cafe or location name
  const searchFilter = (item, value) => {
    if (!value || !item) return true;
    const clean = val => val.trim().toLowerCase().replace(/(-|\/)/g, ' ');
    const compare = i => clean(i).includes(clean(value));

    return clean(value) === ''
      || compare(item.City)
      || compare(item.CafeName)
      || compare(item.Neighborhood);
  };

  useEffect(() => {
    setFilteredData(cafeData.filter(item => (
      (!toggles.includes(WIFI) || item.WifiDown > 20)
        && (!toggles.includes(POWER) || item.Power === '✓')
        && (!toggles.includes(GREAT) || item.TotalScore >= 17)
        && (!toggles.includes(FOOD) || item.FoodScore >= 4)
        && (!toggles.includes(COFFEE) || item.CoffeeScore >= 4)
        && (!toggles.includes(SEATS) || item.SeatsScore >= 4)
        && searchFilter(item, searchValue)
    )));
  }, [toggles, searchValue, cafeData]);

  useEffect(() => {
    let newUrl = '';
    if (view === CAFE) {
      newUrl = `/${CAFE}/`;
      if (selectedCafe) newUrl += stringToUrl(selectedCafe.CafeName);
      else if (searchValue) newUrl += stringToUrl(searchValue);
      if (filteredData && !selectedCafe) {
        selectCafe(filteredData[0]);
        setSearchValue('');
      }
    } else {
      if (toggles.includes(GREAT)) newUrl += 'great-cafes';
      else if (toggles.length > 0) newUrl += 'cafes-with-';
      else if (searchValue) newUrl += 'cafes';
      if (toggles.includes(GREAT) && toggles.length > 1) newUrl += '-with-';
      newUrl += toggles.filter(t => t !== GREAT).join('-and-');
      if (searchValue) newUrl += `-in-${stringToUrl(searchValue)}`;
      if (newUrl.length > 0) newUrl = `/cafe-${view}/${newUrl}`;
    }
    window.history.pushState("", "", window.location.origin + newUrl);
  }, [searchValue, selectedCafe, filteredData, view, toggles]);

  useEffect(() => {
    filteredData.length > 2
      && title
      && view !== CAFE
      && setDescription(`Here are ${filteredData.length} ${title} like ${filteredData[0].CafeName} and ${filteredData[filteredData.length-1].CafeName}.`)
  }, [filteredData, title, view]);

  const selectCafeFromList = (cafe) => {
    selectCafe(cafe);
    setView(CAFE);
  }

  const handleSearch = ({ type, value }) => {
    selectCafe();
    if (type === CAFE) setView(CAFE);
    else if (view === CAFE) setView(MAP);
    setSearchValue(value);
  }

  return (
    <div className="App">
      <Header title={title} description={description} />
      <SEO title={title} description={description} />
      <main className="App-main">
        {cafeData.length > 0 ? (
          <>
            <NavBar
              handleSearch={handleSearch}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              toggleView={toggleView}
              view={view}
              data={cafeData}
              toggles={toggleButtons}
              cafeData={cafeData}
            />
            <section className="App-section">
              {
                view === LIST &&
                  <CafeList
                    data={filteredData}
                    onSelect={selectCafeFromList}
                  />
              }
              { (view === MAP || view === CAFE) &&
                <MapContainer
                  data={filteredData}
                  selectedCafe={selectedCafe}
                  selectCafe={selectCafeFromList}
                />
              }
            </section>
          </>
        ) : (
          <p>Brewing...</p>
        )}
      </main>
      <AddCafeButton link={CAFE_GRIND_SPREADSHEET} text={"➕ add café"} />
    </div>
  )
}

export default App
